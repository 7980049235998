<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .form-check-input {
    margin-bottom: 5px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import CertHeader from "./cert-header";
  import {
    getZskInfo,
    editZsk,
    getWlgs
  } from "@/api/zhengshu/manage.js"
  import {deleteUpload} from "@/api/common/public";
  /**
   * 成绩库导入--发布时间
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      CertHeader,


    },
    data() {
      return {
        title: "证书导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        sid: "",
        addForm: {},
        pickerOptions_start: "",
        pickerOptions_end: "",

        checkForm: [],
        columns: [],
        wlgs:{
          wlgs:'',
          sfbz:''
        },
        showBtn:false, //默认未设置省直考取
      };
    },
    methods: {
      getWlgsList(){
        getWlgs({
          qyzt:1
        }).then(res => {
          if (res.status) {
            if(res.data&&res.data.length>0){
              this.wlgs = res.data[0]
            }
          }
        })
      },
      test() {
        let str = this.checkForm.join(",")
        if (str) {
          this.addForm.yxyjkq = str
        }
      },
      // 获取详情
      getDeatails(zskid) {
        getZskInfo(zskid).then(res => {
          if (res.status) {
            this.addForm = res.data
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
            this.columns = res.data.sykq? res.data.sykq.split(","):[];
            this.checkForm = res.data.yxyjkq?res.data.yxyjkq.split(","):[];
            let sfbz = this.addForm.sfbz||0;
            this.addForm.sfbz = Number(sfbz)/100;

            // 判断一下是不是全部选中的，
            // 如果是一样的，先提示是不是用户想要的，如果不一样则不提示
            var c = this.columns .length === this.checkForm.length && this.columns.filter(t => !this.checkForm.includes(t));
            if(c && this.columns.some(x=>x.indexOf('省直')>-1)){
              this.$confirm("默认设置提示： 是否默认设置允许邮寄考取默认选中省直考区?", "提示", {
                confirmButtonText: "确定",
                type: "warning",
              })
              .then(() => {
                this.showBtn = true
                 this.setonly(true)
              })
            }


          }
        })
      },
      // 修改成绩库
      editItem() {
        let sfbz = this.addForm.sfbz||0;
        this.addForm.sfbz = Number(sfbz)*100;
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        editZsk({
          ...formDate,
          zskid: this.zskid
        }).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: res.message,
              duration: 3000,
            })
            this.$refs.header.getUrl('/admin/certImportCheck')

          }
        })
      },
      wlgsPage(){
        this.$refs.header.getUrl('/admin/certLogisticsManage?isReturn=true&zskid='+this.zskid);
      },
      setonly(v){
        if(v){
          this.checkForm = this.checkForm.filter(x=>x.indexOf('省直')>-1);
        }
      }
    },
    mounted() {
      this.getWlgsList();
      if (this.zskid) {
        this.getDeatails(this.zskid)
      }
    },
    created() {
      this.zskid = this.$route.query["zskid"] ? this.$route.query["zskid"] : "";
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <CertHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <div class="outerCotent mt-3 ">
              <input type="hidden" class=" msgValInput w-30" value="" name="sid">
              <div class="msgContent flexList fs-xs">
                <div class="w-100">
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">仅设置省直考区</div>
                    <div class="msgVal  flexList">
                      <el-checkbox   v-model="showBtn"  label="仅勾选省直考区" class="mb-0 text-warning" @change="setonly">
                        仅勾选省直考区
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel " style="align-items: unset;height: unset">
                  <div class="msgName " style="height: unset">允许邮寄的考区设置
                  </div>
                  <div class="msgVal selectShow  flexList w-33">
                    <el-checkbox-group v-model="checkForm" @change="test">
                      <el-checkbox v-for="(item,index) in columns" :key="index" :value="item" :label="item"
                                   class="mb-0">
                        {{item}}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="w-100">
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">物流公司</div>
                    <div class="msgVal  flexList">
                      {{wlgs.wlgs}}
                      <span v-if="wlgs==null||wlgs.wlgs==null">&#12288;&#12288;&#12288;&#12288;物流信息未添加或未启用
                      <button class="btn btn-info h35 mr-2" @click="wlgsPage">前往物流管理</button>
                      </span>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">收费标准（元）</div>
                    <div class="msgVal  flexList">
                      {{Number(wlgs.sfbz)/100}}（元）
                    </div>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel" style="height: 60px;">
                  <div class="msgName"></div>
                  <div class="msgVal flexList">
                    <button class="btn btn-info h35 mr-2 sub-btn" @click="editItem">确定并进入下一步</button>
                    <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
